<template>
    <div id="setting">
        <div class="content">

        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data(){
            return {

            }
        },
        mounted() {

        },
        methods:{

        },
        watch:{

        }
    }
</script>

<style scoped lang="scss">
    #setting{
        width: 100%;
        height: 100%;
        position: relative;
        padding: pcRem(24) pcRem(20);
        background-color: #f8f8f9;
        .content{
            width: 100%;
            height: 100%;
            padding: pcRem(30) pcRem(40);
            background-color: #fff;
            box-shadow: 0px 3px 6px #ddd;
            border-radius: 4px;

        }

    }
</style>
